import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, useNavigate, useLocation } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Navbar from "./Components/Navbar/Navbar";
import { AuroraHero } from "./Components/Hero/Hero";
import { About } from "./Components/About/About";
import { Skills } from "./Components/Skills/Skills";
import Services from "./Components/Services/Services";
import { Background } from "./Components/Background/Background";
import { ColorProvider } from '../src/Components/Context/ColorContext';
import { Contact } from "./Components/Contact/Contact";
import Projects from "./Components/Projects/Projects";
import Loader from "./Components/PreLoader/Loading";
import useImagePreloader from "./Components/PreLoader/useImagePreloader";
import AnimatedRoutes from './Components/Animations/AnimatedRoutes';

// Import your images

import project1 from "./Assets/project1.png"
import project2 from './Assets/CodeBp.png'
import project3 from "./Assets/socialvis.png"
import project4 from "./Assets/todo.png"
import project5 from "./Assets/ather.png"
import project6 from "./Assets/BaskinRobbins.png"
import web_dev from "./Assets/web_development.png";
import mob_dev from "./Assets/mobile_development.png";
import pc_dev from "./Assets/desktop_development.png";
import html from './Assets/html5-original.svg';
import css from './Assets/css3-original.svg';
import js from './Assets/javascript-original.svg';
import linux from './Assets/linux-original.svg';
import mongo from './Assets/mongodb-original.svg';
import git from './Assets/git-original.svg';
import github from './Assets/github-original.svg';
import python from './Assets/python-original.svg';
import sql from './Assets/mysql-original.svg';
import c from './Assets/c-original.svg';
import cpp from './Assets/cpp.svg';
import npm from './Assets/npm-original-wordmark.svg';
import bootstrap from './Assets/bootstrap-plain.svg';
import reactimg from './Assets/react-2.svg';
import node from './Assets/node.svg';
import postman from './Assets/postman.svg';
import tailwind from './Assets/tailwindcss.svg';
import django from './Assets/django.svg';
import flask from './Assets/flask.svg';
import figma from './Assets/figma-icon.svg';
import gsapimg from './Assets/gsap-greensock.svg';

function App() {
    const images = [
        project1,project2,project3,project4,project5,project6,cpp,web_dev, mob_dev, pc_dev, html, css, js, linux, mongo, git, github, python,
        sql, c, npm, bootstrap, reactimg, node, postman, tailwind, django, flask, figma, gsapimg
    ];
    const loading = useImagePreloader(images);

    return (
        <Router>
            <AppContent loading={loading} />
        </Router>
    );
}

function AppContent({ loading }) {
    const navigate = useNavigate();
    const location = useLocation();
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            // Check if it's a page refresh
            if (performance.navigation.type === 1 && location.pathname !== '/') {
                navigate('/');
            }
        }
    }, [navigate, location]);


    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem('hasAnimated');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    return (
        <div>
            {loading ? (
                    // <AnimatedRoutes>
                        <Loader />
                    // </AnimatedRoutes>

            ) : (
                <ColorProvider>
                    <Navbar />
                    <Background />
                    <AnimatedRoutes>
                        <Route path="/" element={<AuroraHero />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/skills" element={<Skills />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/projects" element={<Projects />} />
                    </AnimatedRoutes>
                </ColorProvider>
            )}
        </div>
    );
}

export default App;