import React from 'react';
import { Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function AnimatedRoutes({ children }) {
    const location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
                <Routes location={location}>{children}</Routes>
            </CSSTransition>
        </TransitionGroup>
    );
}

export default AnimatedRoutes;