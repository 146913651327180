import React from "react";
import { FiArrowRight } from "react-icons/fi";
import {
    useMotionTemplate,
    motion,
} from "framer-motion";
import './About.css'
import { useColor } from '../Context/ColorContext';
import {useNavigate} from "react-router-dom"; // Make sure to import this
import resume from '../../Assets/yashResume.pdf'
export const About = () => {
    const color = useColor(); // Use the shared color
    const navigate = useNavigate()
    const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;
    const border = useMotionTemplate`1px solid ${color}`;
    const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;
    const handleDownload = () => {
        // URL of the file to be downloaded
        const fileUrl = resume;
        const fileName = 'resume.pdf';

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileName);

        // Append the link to the body (necessary for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };
    return (
        <div
            className="fixed top-0 grid min-h-screen overflow-hidden  w-screen  py-24 text-gray-200"
        >
            <div className="relative z-10 flex flex-col items-center h-[88vh] w-screen overflow-auto">
                <div className={'about-head-container'}>
                    <h1 className="about-head text-5xl montserrat-thick">
                        About <span className={'different'}>Me</span>
                    </h1>
                </div>
                <div className="image-container">
                    <div style={{width: '100%', height: '0', paddingBottom: '100%', position: 'relative'}}>
                        <iframe
                            src="https://giphy.com/embed/bpmNf92LmkoMw"
                            width="100%"
                            height="100%"
                            style={{position: 'absolute', borderRadius: '50%'}}
                            frameBorder="0"
                            className="giphy-embed"
                            allowFullScreen
                        ></iframe>
                    </div>

                </div>
                <div className={'about-content'}>
                    <div className={'about-me'}>
                        Hello World 👋! I'm <span className={'different'}>Yash Jewalkar</span> a <span
                        className={'different'}>full-stack</span> developer and a <span className={'different'}>Computer Science</span> student.
                        I've been an enthusiast of <span className={'different'}>programming</span> my whole life.
                        Programming is a journey of <span className={'different'}>continuous learning </span> and <span
                        className={'different'}>problem-solving</span>! I'm passionate about creating efficient and
                        innovative solutions in <span className={'different'}>web development</span>, mobile
                        development, and desktop development.
                    </div>
                    <div className={'about-partition'}></div>
                    <div className={'about-resume'}>
                        <div>
                            You can find me on <span className={'different'}><a href={'https://github.com/Yash12169'}
                                                                                target={'_blank'}>Github</a></span> and <span
                            className={'different'}><a href={'https://www.linkedin.com/in/yash-jewalkar-203b56257/'}
                                                       target={'_blank'}>LinkedIn</a></span>. I'm also a <span
                            className={'different'}>freelancer</span> and a student of Madhav Institute of Technology
                            and Science. You can download my <span className={'different'}>resume</span> by clicking
                            the <span className={'different'}>button</span> below. Besides, if you're interested in a
                            project or you want me to build one, you can contact me. 😀
                        </div>
                        {/*<div className={'btn-container'}>*/}
                            <motion.button
                                style={{
                                    border,
                                    boxShadow,
                                }}
                                whileHover={{
                                    scale: 1.015,
                                }}
                                whileTap={{
                                    scale: 0.985,
                                }}
                                className="group relative flex items-center justify-center gap-1.5 rounded-[10px] bg-gray-950/10 width-btn2 px-4 py-3 text-gray-50 transition-colors hover:bg-gray-950/50"
                                onClick={handleDownload}
                            >
                                Download Resume
                                <FiArrowRight
                                    className="transition-transform group-hover:-rotate-45 group-active:-rotate-12"/>
                            </motion.button>
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};