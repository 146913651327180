import { Stars } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, {useEffect, useState} from "react";
import { FiArrowRight } from "react-icons/fi";
import {
    useMotionTemplate,
    useMotionValue,
    motion,
    animate,
} from "framer-motion";
import './Projects.css'
import web_dev from "../../Assets/web_development.png"
import mob_dev from "../../Assets/mobile_development.png"
import pc_dev from "../../Assets/desktop_development.png"

import project1 from "../../Assets/project1.png"
import project2 from '../../Assets/CodeBp.png'
import project3 from "../../Assets/socialvis.png"
import project4 from "../../Assets/todo.png"
import project5 from "../../Assets/ather.png"
import project6 from "../../Assets/BaskinRobbins.png"




const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

const Projects = () => {
    const color = useMotionValue(COLORS_TOP[0]);
    const [projectHead,setProjectHead] = useState(false)
    const [projectHead2,setProjectHead2] = useState(false)
    const [projectHead3,setProjectHead3] = useState(false)
    const [projectHead4,setProjectHead4] = useState(false)
    const [projectHead5,setProjectHead5] = useState(false)
    const [projectHead6,setProjectHead6] = useState(false)
    useEffect(() => {
        animate(color, COLORS_TOP, {
            ease: "easeInOut",
            duration: 10,
            repeat: Infinity,
            repeatType: "mirror",
        });
    }, []);

    const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;
    const border = useMotionTemplate`1px solid ${color}`;
    const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;
    const containerStyle = {
        backgroundImage: `url(${project1})`,
        backgroundSize: 'cover', // Ensures the image covers the entire container
        backgroundPosition: '', // Centers the image
        backgroundRepeat: 'no-repeat' // Prevents the image from repeating
    };
    const containerStyle2 = {
        backgroundImage: `url(${project2})`,
        backgroundSize: 'cover', // Ensures the image covers the entire container
        backgroundPosition: 'center', // Centers the image
        backgroundRepeat: 'no-repeat' // Prevents the image from repeating
    };
    const containerStyle3 = {
        backgroundImage: `url(${project3})`,
        backgroundSize: 'cover', // Ensures the image covers the entire container
        backgroundPosition: '', // Centers the image
        backgroundRepeat: 'no-repeat' // Prevents the image from repeating
    };
    const containerStyle4 = {
        backgroundImage: `url(${project4})`,
        backgroundSize: 'cover', // Ensures the image covers the entire container
        backgroundPosition: 'center', // Centers the image
        backgroundRepeat: 'no-repeat' // Prevents the image from repeating
    };

    const containerStyle5 = {
        backgroundImage: `url(${project5})`,
        backgroundSize: 'cover', // Ensures the image covers the entire container
        backgroundPosition: 'center', // Centers the image
        backgroundRepeat: 'no-repeat' // Prevents the image from repeating
    };
    const containerStyle6 = {
        backgroundImage: `url(${project6})`,
        backgroundSize: 'cover', // Ensures the image covers the entire container
        backgroundPosition: 'center', // Centers the image
        backgroundRepeat: 'no-repeat' // Prevents the image from repeating
    };
    return (
        <div

            className="absolute top-0 z-[1000] grid min-h-screen overflow-hidden h-[90vh] text-white   py-24"
        >
            <div className="relative z-10 flex flex-col overflow-auto h-[90vh] items-center">

                <div className={'skills-head-container'}>
                    <h1 className="about-head  text-5xl montserrat-thick">
                        My <span className={'different'}>Projects</span>

                    </h1>

                </div>
                <div className={'skills-des project-width'}>
                    Here are some of the <span className={'different'}>Projects</span> that I've been working on.
                </div>
                <div className={'project-content'}>
                    <div className={'project-content-item'} style={containerStyle} onMouseEnter={() => setProjectHead(true)} onMouseLeave={() => setProjectHead(false)}>
                        <div className={`overlay ${projectHead ? 'visible' : ''}`}></div>
                        {projectHead ? (
                            <p className={'project-title'}>Real Time Chat App</p>
                        ) : (<p className={'project-title-invisible'}>Real Time Chat App</p>)}
                        {projectHead ? (
                            <p className='project-des project-des-hovered'>A full stack real time chat app with JWT
                                authentication</p>
                        ) : (
                            <p className='project-des'>A full stack real time chat app with JWT authentication</p>
                        )}
                        <div className={`tech-stack-project ${projectHead ? 'visible' : ''}`}>
                            {['React.js', 'Django', 'MySQL', 'Socket.io', 'JWT'].map((tech, index) => (
                                <div key={index} className="tech-stack">{tech}</div>
                            ))}
                        </div>
                        <div className={`project-bottom-ender ${projectHead ? 'visible' : ''}`}>
                            <div className="tech-stack-2">web</div>
                            <a href={'https://github.com/Yash12169/ChatRoom-Frontend'} target={'_blank'} className="code-linker">
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className={'project-content-item'} style={containerStyle2}
                         onMouseEnter={() => setProjectHead2(true)} onMouseLeave={() => setProjectHead2(false)}>
                        <div className={`overlay ${projectHead2 ? 'visible' : ''}`}></div>
                        {projectHead2 ? (
                            <p className={'project-title'}>CodeBlueprints (In development)</p>
                        ) : (<p className={'project-title-invisible'}>CodeBlueprints (In development)</p>)}
                        {projectHead2 ? (
                            <p className='project-des project-des-hovered'>A premium based template library for 3D
                                animations using GSAP and more</p>
                        ) : (
                            <p className='project-des'>A premium based template library for 3D animations using GSAP and
                                more</p>
                        )}
                        <div className={`tech-stack-project ${projectHead2 ? 'visible' : ''}`}>
                            {['React.js', 'GSAP', 'Framer-Motion', 'Anime.js'].map((tech, index) => (
                                <div key={index} className="tech-stack">{tech}</div>
                            ))}
                        </div>
                        <div className={`project-bottom-ender ${projectHead2 ? 'visible' : ''}`}>
                            <div className="tech-stack-2">web</div>
                            <a href={'https://github.com/Yash12169/CodeBluePrints'} target={'_blank'} className="code-linker">
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className={'project-content-item'} style={containerStyle3} onMouseEnter={() => setProjectHead3(true)} onMouseLeave={() => setProjectHead3(false)}>
                        <div className={`overlay ${projectHead3 ? 'visible' : ''}`}></div>
                        {projectHead3 ? (
                            <p className={'project-title'}>SocialVista</p>
                        ) : (<p className={'project-title-invisible'}>SocialVista</p>)}
                        {projectHead3 ? (
                            <p className='project-des project-des-hovered'>A fullstack social media website with basic
                                features</p>
                        ) : (
                            <p className='project-des'>A fullstack social media website with basic features</p>
                        )}
                        <div className={`tech-stack-project ${projectHead3 ? 'visible' : ''}`}>
                            {['HTML', 'CSS', 'JavaScript', 'Django', 'MySQL'].map((tech, index) => (
                                <div key={index} className="tech-stack">{tech}</div>
                            ))}
                        </div>
                        <div className={`project-bottom-ender ${projectHead3 ? 'visible' : ''}`}>
                            <div className="tech-stack-2">web</div>
                            <a href={'https://github.com/Yash12169/social-media-project-new'} target={'_blank'} className="code-linker">
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className={'project-content-item'} style={containerStyle4} onMouseEnter={() => setProjectHead4(true)} onMouseLeave={() => setProjectHead4(false)}>
                        <div className={`overlay ${projectHead4 ? 'visible' : ''}`}></div>
                        {projectHead4 ? (
                            <p className={'project-title'}>Todo List</p>
                        ) : (<p className={'project-title-invisible'}>Todo List</p>)}
                        {projectHead4 ? (
                            <p className='project-des project-des-hovered ml-[30px] mr-[30px]'>Simple todo list web app
                                to save and manage to-dos, and save them to the local storage.</p>
                        ) : (
                            <p className='project-des ml-[30px] mr-[30px]'>Simple todo list web app to save and manage
                                to-dos, and save them to the local storage.</p>
                        )}
                        <div className={`tech-stack-project ${projectHead4 ? 'visible' : ''}`}>
                            {['HTML', 'CSS', 'JavaScript', 'Django', 'MySQL'].map((tech, index) => (
                                <div key={index} className="tech-stack">{tech}</div>
                            ))}
                        </div>
                        <div className={`project-bottom-ender ${projectHead4 ? 'visible' : ''}`}>
                            <div className="tech-stack-2">web</div>
                            <a href={'https://github.com/Yash12169/Todo_list_django'} target={'_blank'} className="code-linker">
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className={'project-content-item'} style={containerStyle5} onMouseEnter={() => setProjectHead5(true)} onMouseLeave={() => setProjectHead5(false)}>
                        <div className={`overlay ${projectHead5 ? 'visible' : ''}`}></div>
                        {projectHead5 ? (
                            <p className={'project-title'}>Ather-Redesigned</p>
                        ) : (<p className={'project-title-invisible'}>Ather-Redesigned</p>)}
                        {projectHead5 ? (
                            <p className='project-des project-des-hovered ml-[30px] mr-[30px]'>Redesigned the website of
                                ather electric to make it more visually appealing and to increase user engagement</p>
                        ) : (
                            <p className='project-des ml-[30px] mr-[30px]'>Redesigned the website of ather electric to
                                make it more visually appealing and to increase user engagement</p>
                        )}
                        <div className={`tech-stack-project ${projectHead5 ? 'visible' : ''}`}>
                            {['React.js', 'GSAP', 'Framer-Motion', 'Anime.js'].map((tech, index) => (
                                <div key={index} className="tech-stack">{tech}</div>
                            ))}
                        </div>
                        <div className={`project-bottom-ender ${projectHead5 ? 'visible' : ''}`}>
                            <div className="tech-stack-3">Design</div>
                            <a href={'https://github.com/Yash12169/The-Scripting-Squad-ReimagineRound1'} target={'_blank'} className="code-linker">
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className={'project-content-item'} style={containerStyle6} onMouseEnter={() => setProjectHead6(true)} onMouseLeave={() => setProjectHead6(false)}>
                        <div className={`overlay ${projectHead6 ? 'visible' : ''}`}></div>
                        {projectHead6 ? (
                            <p className={'project-title'}>Baskin-Robbins Redesigned</p>
                        ) : (<p className={'project-title-invisible'}>Baskin-Robbins Redesigned</p>)}
                        {projectHead6 ? (
                            <p className='project-des project-des-hovered ml-[30px] mr-[30px]'>Redesigned the website of
                                Baskin-Robbins India to make it more visually appealing and to increase user
                                engagement</p>
                        ) : (
                            <p className='project-des ml-[30px] mr-[30px]'>Simple todo list web app to save and manage
                                to-dos, and save them to the local storage.</p>
                        )}
                        <div className={`tech-stack-project ${projectHead6 ? 'visible' : ''}`}>
                            {['React.js', 'GSAP', 'Framer-Motion', 'Anime.js'].map((tech, index) => (
                                <div key={index} className="tech-stack">{tech}</div>
                            ))}
                        </div>
                        <div className={`project-bottom-ender ${projectHead6 ? 'visible' : ''}`}>
                            <div className="tech-stack-3">Design</div>
                            <a href={'https://github.com/Yash12169/The-Scripting-Squad-ReimagineRound2'} target={'_blank'} className="code-linker">
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>
                    </div>


                </div>

            </div>


        </div>
    );
};
export default Projects