import React from "react";
import { FiArrowRight } from "react-icons/fi";
import {
    useMotionTemplate,
    motion,
} from "framer-motion";
import './Contact.css'
import { useColor } from '../Context/ColorContext'; // Make sure to import this
import { AnimatePresence} from "framer-motion";
import { FiAlertCircle } from "react-icons/fi";
import { useState } from "react";



const SpringModal = ({ isOpen, setIsOpen }) => {
    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsOpen(false)}
                    className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "12.5deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className="bg-gradient-to-br from-violet-600 to-indigo-600 text-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
                    >
                        <FiAlertCircle className="text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" />
                        <div className="relative z-10">

                            <h3 className="text-3xl font-bold text-center mb-2">
                               Form Submitted!
                            </h3>
                            <p className="text-center mb-6">
                                Thank you for contacting me i will get back to you as soon as possible, in the meantime take a look at my projects and resume.
                            </p>
                            <div className="flex gap-2">

                                <button
                                    onClick={() => setIsOpen(false)}
                                    className="bg-white hover:opacity-90 transition-opacity text-indigo-600 font-semibold w-full py-2 rounded"
                                >
                                    Understood!
                                </button>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
const SpringModal2 = ({ isOpen2, setIsOpen2 }) => {
    return (
        <AnimatePresence>
            {isOpen2 && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsOpen2(false)}
                    className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "12.5deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className="bg-gradient-to-br from-red-900 to-red-600 text-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
                    >
                        <FiAlertCircle className="text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" />
                        <div className="relative z-10">
                            <div
                                className="bg-white w-16 h-16 mb-2 rounded-full text-3xl text-red-600 grid place-items-center mx-auto">
                                <FiAlertCircle/>
                            </div>
                            <h3 className="text-3xl font-bold text-center mb-2">
                                Missing Values!
                            </h3>
                            <p className="text-center mb-6">
                                Please fill all the entries below to submit the form.
                            </p>
                            <div className="flex gap-2">

                                <button
                                    onClick={() => setIsOpen2(false)}
                                    className="bg-white hover:opacity-90 transition-opacity text-red-600 font-semibold w-full py-2 rounded"
                                >
                                    Understood!
                                </button>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export const Contact = () => {
    const color = useColor(); // Use the shared color

    const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;
    const border = useMotionTemplate`1px solid ${color}`;
    const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;

    const [name,setName] = useState('')
    const [subject,setSubject] = useState('')
    const [email,setEmail] = useState('')
    const [message,setMessage] = useState('')

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const handleModal  = () => {
        if(name.length && subject.length && email.length && message.length){

            setIsOpen(true)
            setName('')
            setMessage('')
            setSubject('')
            setEmail('')
        }
        else{
            setIsOpen2(true)
        }

    }
    return (
        <div
            className="fixed top-0 grid min-h-screen overflow-hidden w-screen  py-24 text-gray-200"
        >
            <div className="relative z-10 flex flex-col overflow-x-hidden overflow-auto h-[80vh] items-center">
                <div className={'about-head-container'}>
                    <h1 className="about-head text-5xl montserrat-thick">
                        Contact <span className={'different'}>Me</span>
                    </h1>
                </div>
                <div className={'contact-des text-center'}>
                    Drop me a message, and I'll get back to you as soon as possible. <span className={'different'}>Email</span> is the best way to get in touch with me directly.
                </div>
                <div className={'contact-content'}>
                    <div className={'contact-input-container'}>
                        <input className={'contact-input'} onChange={(e)=>setName(e.target.value)} value={name} type={'text'} placeholder={'Name'}/>
                        <input className={'contact-input'} onChange={(e)=>setSubject(e.target.value)} value={subject} type={'text'} placeholder={'Subject'}/>
                        <input className={'contact-input'} onChange={(e)=>setEmail(e.target.value)} value={email} type={'text'} placeholder={'Your email'}/>
                        <textarea className={'contact-input-textarea'} onChange={(e)=>setMessage(e.target.value)} value={message} placeholder={'Your message'}/>
                        <motion.button
                            style={{
                                border,
                                boxShadow,
                            }}
                            whileHover={{
                                scale: 1.015,
                            }}
                            whileTap={{
                                scale: 0.985,
                            }}
                            onClick={handleModal}
                            className="group relative flex items-center justify-center gap-1.5 rounded-[10px] bg-gray-950/10 btn-width-contact px-4 py-3 text-gray-50 transition-colors hover:bg-gray-950/50"
                        >
                            Submit

                        </motion.button>
                        <SpringModal isOpen={isOpen} setIsOpen={setIsOpen}/>
                        <SpringModal2 isOpen2={isOpen2} setIsOpen2={setIsOpen2}/>
                    </div>
                    <div className={'contact-details'}>
                        <div className={'contact-details-item'}>
                            <svg className="w-6 h-6" fill="#8798AE" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                                      clip-rule="evenodd"></path>
                            </svg>
                            <p className={'contact-details-head'}>jewalkaryash@gmail.com</p>
                            <p className={'contact-details-des'}> Email me for any questions or inquiries. </p>
                        </div>
                        <div className={'contact-details-item'}>
                            <svg className="w-6 h-6" fill="#8798AE" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                            </svg>
                            <p className={'contact-details-head'}>+91-6263758330</p>
                            <p className={'contact-details-des'}> Call me for any questions or inquiries. </p>
                        </div>
                        <div className={'contact-details-item'}>
                            <svg className="w-6 h-6" fill="#8798AE" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                      clip-rule="evenodd"></path>
                            </svg>
                            <p className={'contact-details-head'}>Indore, Madhya Pradesh, India</p>
                            <p className={'contact-details-des'}> Visit me for any questions or inquiries. </p>
                        </div>

                    </div>
                </div>
                <div className={'socials'}>

                    <p className={'socials-head'}>Follow me on</p>
                    <div className={'links'}>
                        <a href={'https://www.linkedin.com/in/yash-jewalkar-203b56257/'} target={"_blank"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5"
                                 className="w-8 h-8 transition delay-75 hover:text-primary hover:scale-125 svg-hover"
                                 fill="none"
                                 stroke="#8798AE" stroke-linecap="round" stroke-linejoin="round">

                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <rect x="4" y="4" width="16" height="16" rx="2"></rect>
                                <line x1="8" y1="11" x2="8" y2="16"></line>
                                <line x1="8" y1="8" x2="8" y2="8.01"></line>
                                <line x1="12" y1="16" x2="12" y2="11"></line>
                                <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                            </svg>
                        </a>
                        <a href={'https://github.com/Yash12169'} target={'_blank'}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5"
                                 className="w-8 h-8 transition delay-75 hover:text-primary svg-hover hover:scale-125"
                                 fill="none"
                                 stroke="#8798AE" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path
                                    d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5"></path>
                            </svg>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    );
};