
import React, {useState,useEffect,useRef} from "react";
import { FiArrowRight } from "react-icons/fi";
import {
    useMotionTemplate,
    motion,
} from "framer-motion";
import './Hero.css';
import { useColor } from '../Context/ColorContext';
import TextReveal from "../Animations/TextReveal";
import { TypeAnimation } from 'react-type-animation';
import resume from "../../Assets/yashResume.pdf";
export const AuroraHero = () => {
    const color = useColor();

    const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;
    const border = useMotionTemplate`1px solid ${color}`;
    const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;
    const [percentage,setPercentage]=useState(0)
    const [four, setFour] = useState(false);
    const [five, setFive]=useState(false);
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const initialRender = useRef(true);








    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            const hasAnimated = localStorage.getItem('hasAnimated');
            if (!hasAnimated) {
                setShouldAnimate(true);
                localStorage.setItem('hasAnimated', 'true');
            }
        }
    }, []);

    useEffect(() => {
        if (!shouldAnimate) return;

        const timer = setTimeout(() => {
            setFour(true);
        }, 5000);

        return () => clearTimeout(timer);
    }, [shouldAnimate]);

    useEffect(() => {
        if (!shouldAnimate) return;

        const timer = setTimeout(() => {
            setFive(true);
        }, 5100);

        return () => clearTimeout(timer);
    }, [shouldAnimate]);

    useEffect(() => {
        if (!shouldAnimate) return;

        let interval = null;
        const delay = setTimeout(() => {
            if (percentage < 100) {
                interval = setInterval(() => {
                    setPercentage((prev) => {
                        const newPercentage = Math.min(prev + 2, 100);
                        if (newPercentage === 100) {
                            clearInterval(interval);
                        }
                        return newPercentage;
                    });
                }, 30);
            }
        }, 3000);

        return () => {
            clearTimeout(delay);
            if (interval) clearInterval(interval);
        };
    }, [shouldAnimate, percentage]);













    const handleDownload = () => {
        // URL of the file to be downloaded
        const fileUrl = resume;
        const fileName = 'resume.pdf';

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileName);

        // Append the link to the body (necessary for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setFour(true);
        }, 5000); // 5000 milliseconds = 5 seconds

        // Cleanup the timeout if the component unmounts before the 5 seconds
        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            setFive(true);
        }, 5100); // 5000 milliseconds = 5 seconds

        // Cleanup the timeout if the component unmounts before the 5 seconds
        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        let interval = null;
        const delay = setTimeout(() => {
            if (percentage < 100) {
                interval = setInterval(() => {
                    setPercentage((prev) => {
                        const newPercentage = Math.min(prev + 2, 100);
                        if (newPercentage === 100) {
                            clearInterval(interval);
                        }
                        return newPercentage;
                    });
                }, 30); // 100 milliseconds = 0.1 second
            }
        }, 3000); // 2000 milliseconds = 2 seconds

        return () => {
            clearTimeout(delay);
            if (interval) clearInterval(interval);
        };
    }, []);


    return (
        <div
            className="fixed top-0 left-0 grid min-h-screen place-content-center overflow-hidden   pd-extra text-gray-200 w-screen"
        >
            <div className="relative z-10 flex flex-col items-center w-screen">
                <div className="image-container">
                    <div style={{width: '100%', height: '0', paddingBottom: '100%', position: 'relative'}}>
                        <iframe
                            src="https://giphy.com/embed/bpmNf92LmkoMw"
                            width="100%"
                            height="100%"
                            style={{position: 'absolute',borderRadius:'50%'}}
                            frameBorder="0"
                            className="giphy-embed"
                            allowFullScreen
                        ></iframe>
                    </div>

                </div>
                <div className={'name-img-container'}>
                    {/*<h1 className="max-w-3xl  montserrat-thick to-gray-400 text-[#A6ADBA] text-center text-3xl font-medium leading-tight text-transparent sm:text-5xl sm:leading-tight md:text-5xl md:leading-tight">*/}
                    {/*    Yash Jewalkar*/}
                    {/*</h1>*/}
                    <h1 className="montserrat-thick my-name">
                        Yash Jewalkar
                    </h1>
                    <svg className="w-8 h-8 sm:w-9 sm:h-9 mt-1" fill="#A6ADBA" viewBox="0 0 20 20"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"></path>
                    </svg>
                </div>
                <div className={'code-portion'}>
                    <div className={'dots-container'}>
                        <div className={'dots redd'}></div>
                        <div className={'dots yellowd'}></div>
                        <div className={'dots greend'}></div>
                    </div>
                    <div className={'code'}>
                        <div className={'code-line'}>
                            <div className={'mar-rig'}>$</div>
                            <div className={'inconsolata-font'}>
                                <TypeAnimation
                                    sequence={shouldAnimate ? [
                                        'npm',
                                        500,
                                        'npm install',
                                        700,
                                        'npm install @resume',
                                        () => {
                                            console.log('Sequence completed');
                                        },
                                    ] : ['npm install @resume']}
                                    wrapper="span"
                                    cursor={shouldAnimate}
                                />

                            </div>
                        </div>
                        <TextReveal slow={false} delay={2}>
                            <div className={'code-line yellow'}>
                                <div className={'mar-rig'}>></div>
                                <div className={'inconsolata-font'}>installing...{percentage}%</div>
                            </div>
                        </TextReveal>

                        {four && (
                            <div className={'code-line green'}>
                                <div className={'mar-rig'}>></div>
                                <div className={'inconsolata-font'}>resume loaded successfully</div>
                            </div>
                        )}


                        {five && (
                            <div className={'code-line faint'}>
                                <div className={'mar-rig'}>#</div>
                                <div className={'inconsolata-font'}>Click below to download my resume</div>
                            </div>
                        )}

                    </div>
                </div>
                <TextReveal slow={true} delay={5.5}>
                    <div className={'btn-container w-screen'}>
                        <motion.button
                            style={{
                                border,
                                boxShadow,
                            }}
                            onClick={handleDownload}
                            whileHover={{
                                scale: 1.015,
                            }}
                            whileTap={{
                                scale: 0.985,
                            }}
                            className="group relative flex items-center justify-center gap-1.5 rounded-[10px] bg-gray-950/10 width-btn px-4 py-3 text-gray-50 transition-colors hover:bg-gray-950/50"
                        >
                            Download Resume
                            <FiArrowRight
                                className="transition-transform group-hover:-rotate-45 group-active:-rotate-12"/>
                        </motion.button>
                    </div>
                </TextReveal>
            </div>
        </div>
    );
};