import { Stars } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { useEffect } from "react";
import { FiArrowRight } from "react-icons/fi";
import {
    useMotionTemplate,
    useMotionValue,
    motion,
    animate,
} from "framer-motion";
import './Services.css'
import web_dev from "../../Assets/web_development.png"
import mob_dev from "../../Assets/mobile_development.png"
import pc_dev from "../../Assets/desktop_development.png"






const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

const Services = () => {
    const color = useMotionValue(COLORS_TOP[0]);

    useEffect(() => {
        animate(color, COLORS_TOP, {
            ease: "easeInOut",
            duration: 10,
            repeat: Infinity,
            repeatType: "mirror",
        });
    }, []);

    const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;
    const border = useMotionTemplate`1px solid ${color}`;
    const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;

    return (
        <div

            className="fixed top-0 z-[1000] grid min-h-screen overflow-hidden text-white  px-4 py-24  w-screen"
        >
            <div className="relative z-10 flex flex-col overflow-auto h-[88vh] items-center">

                <div className={'skills-head-container'}>
                    <h1 className="about-head  text-5xl montserrat-thick">
                        My <span className={'different'}>Services</span>

                    </h1>

                </div>
                <div className={'skills-des'}>
                    Here are some of the <span className={'different'}>services</span> I provide for clients
                </div>
                <div className={'service-content'}>


                    <div className={'service-content-item'}>
                        <img className={'service-image'} src={web_dev}/>
                        <p className={'service-image-title'}>Web Development</p>
                        <p>As a full-stack developer, I create end-to-end web solutions. From responsive front-end designs to robust back-end systems, I ensure your web application is performant, secure, and scalable.</p>

                    </div>
                    <div className={'service-content-item'}>
                        <img className={'service-image'} src={mob_dev}/>
                        <p className={'service-image-title'}>Web Design</p>
<p>I craft visually stunning and functional web designs that captivate your audience. Whether designing a new site or redesigning an existing one, I bring your vision to life with creativity.</p>
                    </div>
                    <div className={'service-content-item'}>
                        <img className={'service-image'} src={pc_dev}/>
                        <p className={'service-image-title'}>Database Management</p>
                        <p>I design and implement efficient database structures, optimize queries, and ensure data integrity. Whether it's SQL or NoSQL, I've got your data needs covered with reliable, scalable solutions.






                        </p>
                    </div>

                </div>

            </div>


        </div>
    );
};
export default Services