// useImagePreloader.js
import { useState, useEffect } from 'react';

const useImagePreloader = (imageUrls) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let loadedImages = 0;
        const totalImages = imageUrls.length;

        const onLoad = () => {
            loadedImages += 1;
            if (loadedImages === totalImages) {
                setLoading(false);
            }
        };

        imageUrls.forEach((url) => {
            const img = new Image();
            img.src = url;
            img.onload = onLoad;
            img.onerror = onLoad; // Consider it loaded even if there's an error
        });
    }, [imageUrls]);

    return loading;
};

export default useImagePreloader;
