import React from 'react';
import './Menu.css'; // You'll need to convert the SCSS to CSS

const Menu = ({ isOpen, onToggle }) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className={`menu btn11 ${isOpen ? 'open' : ''}`}
                        data-menu="11"
                        onClick={onToggle}
                    >
                        <div className="icon-left"></div>
                        <div className="icon-right"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu;