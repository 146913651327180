import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css'
import { useNavigate } from "react-router-dom";
import Menu from "../Menu/Menu";

function Navbar() {
    const [home, setHome] = useState(true)
    const [projects, setProjects] = useState(false)
    const [about, setAbout] = useState(false)
    const [skills, setSkills] = useState(false)
    const [services, setServices] = useState(false)
    const [contact, setContact] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate()
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleNavigation = (path, setter) => {
        setIsOpen(false); // Close the menu
        setSkills(false);
        setContact(false);
        setServices(false);
        setProjects(false);
        setAbout(false);
        setHome(false);
        setter(true);
        navigate(path);
    };

    const handleAbout = () => handleNavigation('/about', setAbout);
    const handleHome = () => handleNavigation('/', setHome);
    const handleSkills = () => handleNavigation('/skills', setSkills);
    const handleProjects = () => handleNavigation('/projects', setProjects);
    const handleServices = () => handleNavigation('/services', setServices);
    const handleContact = () => handleNavigation('/contact', setContact);

    useEffect(() => {
        function handleClickOutside(event) {
            if (isOpen && menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, menuRef]);

    return (
        <nav>
            <div className={'nav-name-container'}>
                <div className={'hamburger-menu'} ref={menuRef}>
                    <Menu isOpen={isOpen} onToggle={toggleMenu} />

                    <div className={isOpen ? 'nav-links-container-menu' : 'nav-links-container-menu-invi'}>
                        <div className={`${home ? 'nav-links-clicked-menu' : 'nav-links-menu'}`} onClick={handleHome}>Home</div>
                        <div className={`${about ? 'nav-links-clicked-menu' : 'nav-links-menu'}`} onClick={handleAbout}>About</div>
                        <div className={`${skills ? 'nav-links-clicked-menu' : 'nav-links-menu'}`} onClick={handleSkills}>Skills</div>
                        <div className={`${projects ? 'nav-links-clicked-menu' : 'nav-links-menu'}`} onClick={handleProjects}>Projects</div>
                        <div className={`${services ? 'nav-links-clicked-menu' : 'nav-links-menu'}`} onClick={handleServices}>Services</div>
                        <div className={`${contact ? 'nav-links-clicked-menu' : 'nav-links-menu'}`} onClick={handleContact}>Contact</div>
                    </div>
                </div>
                <p className={'nav-name montserrat-thick'}>Yash<span className={'different'}>.</span></p>
            </div>
            <div className={'nav-links-container'}>
                <div className={`${home ? 'nav-links-clicked' : 'nav-links'}`} onClick={handleHome}>Home</div>
                <div className={`${about ? 'nav-links-clicked' : 'nav-links'}`} onClick={handleAbout}>About</div>
                <div className={`${skills ? 'nav-links-clicked' : 'nav-links'}`} onClick={handleSkills}>Skills</div>
                <div className={`${projects ? 'nav-links-clicked' : 'nav-links'}`} onClick={handleProjects}>Projects</div>
                <div className={`${services ? 'nav-links-clicked' : 'nav-links'}`} onClick={handleServices}>Services</div>
                <div className={`${contact ? 'nav-links-clicked' : 'nav-links'}`} onClick={handleContact}>Contact</div>
            </div>
            <div className={'theme-parent'}>
                <div className={'theme-container'}>
                    <a href={'https://www.linkedin.com/in/yash-jewalkar-203b56257/'} target={"_blank"} rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5"
                             className="w-8 h-8 transition delay-75 hover:text-primary hover:scale-125 svg-hover"
                             fill="none"
                             stroke="#8798AE" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <rect x="4" y="4" width="16" height="16" rx="2"></rect>
                            <line x1="8" y1="11" x2="8" y2="16"></line>
                            <line x1="8" y1="8" x2="8" y2="8.01"></line>
                            <line x1="12" y1="16" x2="12" y2="11"></line>
                            <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                        </svg>
                    </a>
                    <a href={'https://github.com/Yash12169'} target={'_blank'} rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5"
                             className="w-8 h-8 transition delay-75 hover:text-primary svg-hover hover:scale-125"
                             fill="none"
                             stroke="#8798AE" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path
                                d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5"></path>
                        </svg>
                    </a>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;