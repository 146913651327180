import { Stars } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { useEffect } from "react";
import { FiArrowRight } from "react-icons/fi";
import {
    useMotionTemplate,
    useMotionValue,
    motion,
    animate,
} from "framer-motion";
import './Skills.css'

import html from '../../Assets/html5-original.svg'
import css from '../../Assets/css3-original.svg'
import js from '../../Assets/javascript-original.svg'
import linux from '../../Assets/linux-original.svg'


import mongo from '../../Assets/mongodb-original.svg'
import git from '../../Assets/git-original.svg'
import github from '../../Assets/github-original.svg'
import python from '../../Assets/python-original.svg'



import sql from '../../Assets/mysql-original.svg'
import c from '../../Assets/c-original.svg'
import npm from '../../Assets/npm-original-wordmark.svg'
import bootstrap from '../../Assets/bootstrap-plain.svg'


import reactimg from '../../Assets/react-2.svg';
import node from '../../Assets/node.svg'
import postman from '../../Assets/cpp.svg'
import tailwind from '../../Assets/tailwindcss.svg'


import django from '../../Assets/django.svg'
import flask from '../../Assets/flask.svg'
import figma from '../../Assets/figma-icon.svg'
import gsapimg from '../../Assets/gsap-greensock.svg'



const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

export const Skills = () => {
    const color = useMotionValue(COLORS_TOP[0]);

    useEffect(() => {
        animate(color, COLORS_TOP, {
            ease: "easeInOut",
            duration: 10,
            repeat: Infinity,
            repeatType: "mirror",
        });
    }, []);

    const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;
    const border = useMotionTemplate`1px solid ${color}`;
    const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;

    return (
        <div

            className="fixed top-0 grid min-h-screen  overflow-hidden w-screen  py-24 text-gray-200"
        >
            <div className="relative z-10 flex flex-col overflow-auto h-[88vh] items-center">

                <div className={'skills-head-container'}>
                    <h1 className="about-head  text-5xl montserrat-thick">
                        My <span className={'different'}>Skills</span>

                    </h1>

                </div>
                <div className={'skills-des'}>
                    Here are some of my <span className={'different'}>skills</span> and <span className={'different'}>technologies</span> that I've been working with
                </div>
                <div className={'skills-content w-screen'}>

                    <div className={'skills-items'}>
                        <div className={'skills-unit'}>
                            <img src={html} className={'img-adjust'}/>
                            <p>HTML</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={css} className={'img-adjust'}/>
                            <p>CSS</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={js} className={'img-adjust'}/>
                            <p>JavaScript</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={linux} className={'img-adjust'}/>
                            <p>Linux</p>
                        </div>

                        <div className={'skills-unit'}>
                            {/*<div className={'skills-image-container'}>*/}
                            <img src={mongo} className={'img-adjust'}/>
                            {/*</div>*/}
                            <p>MongoDB</p>
                        </div>

                        <div className={'skills-unit'}>
                            <img src={github} className={'img-adjust'}/>
                            <p>Github</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={python} className={'img-adjust'}/>
                            <p>Python</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={git} className={'img-adjust'}/>
                            <p>Git</p>
                        </div>

                        <div className={'skills-unit'}>
                            {/*<div className={'skills-image-container'}>*/}
                            <img src={c} className={'img-adjust'}/>
                            {/*</div>*/}
                            <p>C</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={sql} className={'img-adjust'}/>
                            <p>MySQL</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={bootstrap} className={'img-adjust'}/>
                            <p>Bootstrap</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={npm} className={'img-adjust'}/>
                            <p>NPM</p>
                        </div>

                        <div className={'skills-unit'}>
                            {/*<div className={'skills-image-container'}>*/}
                            <img src={reactimg} className={'img-adjust'}/>
                            {/*</div>*/}
                            <p>React.js</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={node} className={'img-adjust'}/>
                            <p>Node.js</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={tailwind} className={'img-adjust2'}/>
                            <p>Tailwind CSS</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={postman} className={'img-adjust'}/>
                            <p>C++</p>
                        </div>

                        <div className={'skills-unit'}>
                            {/*<div className={'skills-image-container'}>*/}
                            <img src={django} className={'img-adjust'}/>
                            {/*</div>*/}
                            <p>Django</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={flask} className={'img-adjust'}/>
                            <p>Flask</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={figma} className={'img-adjust'}/>
                            <p>Figma</p>
                        </div>
                        <div className={'skills-unit'}>
                            <img src={gsapimg} className={'img-adjust'}/>
                            <p>GSAP</p>
                        </div>
                    </div>

                </div>

            </div>


        </div>
    );
};