import React, { createContext, useContext, useEffect } from 'react';
import { useMotionValue, animate } from 'framer-motion';

const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

const ColorContext = createContext();

export const ColorProvider = ({ children }) => {
    const color = useMotionValue(COLORS_TOP[0]);

    useEffect(() => {
        animate(color, COLORS_TOP, {
            ease: "easeInOut",
            duration: 10,
            repeat: Infinity,
            repeatType: "mirror",
        });
    }, []);

    return (
        <ColorContext.Provider value={color}>
            {children}
        </ColorContext.Provider>
    );
};

export const useColor = () => useContext(ColorContext);